import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { Utils } from '@core/utilities/utils';
import { ContactInformation } from '@shared/models/common/contact-information.model';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { Observable, of } from 'rxjs';
import { CacheService } from './cache.service';

@Injectable({ providedIn: 'root' })
export class SharedContactService {
  private WORKSHEET_KEYS = [WorksheetKeys.MDS1, WorksheetKeys.MDS2, WorksheetKeys.GHG];
  private cache: CacheService;
  httpClient: HttpClient;

  constructor(injector: Injector) {
    this.httpClient = injector.get(HttpClient);
    this.cache = injector.get(CacheService);
  }

  get(id: string): Observable<ContactInformation> {
    return this.httpClient.get(`${URLs.SHARED_CONTACT_ENDPOINT}/${id}`) as Observable<ContactInformation>;
  }

  save(contact: ContactInformation): Observable<ContactInformation> {
    return this.httpClient.post(URLs.SHARED_CONTACT_ENDPOINT, contact) as Observable<ContactInformation>;
  }

  delete(id: string): Observable<boolean> {
    return this.httpClient.delete(`${URLs.SHARED_CONTACT_ENDPOINT}/${id}`) as Observable<boolean>;
  }

  update(contact: ContactInformation, isAuthenticated?: boolean): Observable<ContactInformation> {
    if (isAuthenticated !== undefined && isAuthenticated === false) {
      for (let key of this.WORKSHEET_KEYS) {
        const worksheet = this.cache.getWorksheet(key);
        if (!worksheet) {
          continue;
        }
        const found = Utils.findById(worksheet, contact.id);
        if (found) {
          Utils.mergeObject(found, contact);
          this.cache.setWorksheet(worksheet, key);
          return of(found);
        }
      }
      return of(undefined);
    } else {
      const url = `${URLs.SHARED_CONTACT_ENDPOINT}/${contact.id}`;
      return this.httpClient.put(url, contact) as Observable<ContactInformation>;
    }
  }
}
