import { Injectable, Injector } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { ContactInformation } from '@shared/models/common/contact-information.model';
import { EMPTY, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { SharedContactService } from './shared-contact.service';

@Injectable({ providedIn: 'root' })
export class UserContactService extends SharedContactService {

  contact: ContactInformation = undefined;

  constructor(injector: Injector) {
    super(injector);
  }

  bind(userAccountId: string) {
    return this.get(userAccountId)
      .pipe(
        distinctUntilChanged(),
        catchError((err) => EMPTY),
        tap((res) => this.contact = res ? res : undefined));
  }

  get(userAccountid: string): Observable<any> {
    return this.httpClient.get(`${URLs.SHARED_CONTACT_USER_ACCOUNT_ENDPOINT}/${userAccountid}`) as Observable<any>;
  }
}
